.contactUsButton {
    position: fixed;
    bottom: 55px; /* Adjust this value to position it vertically */
    right: 30px;  /* Adjust this value to position it horizontally */
    background-color: #0056b3; /* Customize the button color */
    color: white;              /* Customize the text color */
    border: none;
    padding: 20px 20px;
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
    cursor: pointer;
    z-index: 1000; /* Ensure it stays on top of other elements */
    transition: background-color 0.3s ease; /* Optional: Add hover effect */
}

.contactUsButton:hover {
    background-color: #4b74da;
}


/* Responsive Styling */
@media (max-width: 768px) {

    .contactUsButton {
        bottom: 50px;
        right: 50px;
        font-size: 16px;
        padding: 8px 16px;
    }
}

@media (max-width: 576px) {

    .contactUsButton {
        bottom: 40px;
        right: 20px;
        font-size: 14px;
        padding: 6px 12px;
    }
}