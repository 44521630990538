/* LatestBlogs.module.css */

.latestBlogs {
    padding: 20px;
}

.heading {
    font-family: Suisse Screen, sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: black;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
}

.heading:hover::after {
    content: '';
    position: absolute;
    left: 50%;
    /* Center the underline */
    bottom: -5px;
    transform: translateX(-50%);
    /* Adjust to center the underline */
    width: 20%;
    /* Adjust the width of the underline */
    height: 2px;
    background-color: #1755F4;
    transition: width 0.3s ease;
}

.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
}

.card {
    width: calc(25% - 20px); /* Four cards in one row */
    margin-bottom: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.card:hover {
    transform: translateY(-5px);
}

@media (min-width: 768px) {
    .card {
        width: calc(50% - 20px); /* Two cards in one row */
    }
   
}

@media (max-width: 768px) {
    .cardsContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .card{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 1024px) {
    .card {
        width: calc(25% - 20px); /* Three cards in one row */
    }
}

.blogImage {
    width: 304px; /* Adjusted width */
    height: 244px; /* Adjusted height */
    object-fit: cover;
    transition: transform 0.3s ease;
}

.card:hover .blogImage {
    transform: scale(1.05);
}

.caption {
    font-family: Suisse Screen, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;
    padding: 20px;
}

.details {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    padding: 0 20px;
    font-family: Suisse Screen, sans-serif;
}

.date {
    color: #1755F4;
}

.separator {
    margin: 0 5px;
}

.category {
    color: #1755F4;
}

.description {
    font-family: Suisse Screen, sans-serif;
    font-size: 16px;
    font-weight: 350;
    line-height: 16.94px;
    text-align: left;
    padding: 14px;
    color: black;
}

.horizontalLine {
    width: 90%;
    margin-top: 30px;
    border: 0;
    border-top: 1px solid #ccc;
}
.exploreButton {
    background-color: #1755F4;
    color: white;
    font-family: Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px; /* Adjusted margin for better spacing */
    text-align: center;
    align-items: center;
}