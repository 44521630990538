/* SiteFooter.module.css */

.footer {
    background-color: #f8f9fa;
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footerSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-right: 100px;
    width: auto;
}

.footerSet {
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;
}
.footerSet1 {
    flex: 1;
    margin-left: 20px;
    margin-right: 100px;
}

 
.footerSet h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #1755F4;
}

.footerSet ul {
    list-style-type: none;
    padding: 0;
}

.footerSet ul li {
    margin-bottom: 5px;
    cursor: pointer;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 300;
    font-size: 15px;
}

.verticalLine {
    border-left: 2px solid #ccc;
    height: 175px;
    margin: 0 15px;
    margin-left: 20px;
}
.verticalLine1 {
    border-left: 2px solid #ccc;
    height: 50px;
    margin: 0 15px;
    margin-left: 20px;
}
.footerIcons {
    display: flex;
    align-items: center;
}

.footerIcons svg {
    margin-right: 10px;
    margin: 10px;
    cursor: pointer;
}

.footerLogo {
    display: flex;
    align-items: center;
}

.footerLogo p {
    font-size: 14px;
}
.logo span {
    font-family: Satoshi;
    font-size: 35px;
    font-weight: 800;
    line-height: 51.3px;
    text-align: left;
    color: #1755F4;
    padding: 10px 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Add shadow effect */
}

/* Responsive Styles */
@media screen and (max-width: 968px) {
    .footer {
        flex-direction: column;
        padding: 20px 0 20px 0;
        margin-right: 0px;
    }
    .footerSection{
        flex-direction: column;
        align-items: center;
        justify-content: center;
       
    }
    .footerSet {
        margin: 0;
        text-align: center;
        flex-direction: column;
    }
    .verticalLine,
    .verticalLine1 {
        display: none;
    }
    .footerIcons {
        justify-content: center;
    }
}
