/* ExploreStory.module.css */

.container {
    text-align: center;
    margin-top: 50px;
}

.heading {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 35px;
    font-weight: 500;
    line-height: 64.8px;
    margin-bottom: 25px;
}

.exploreButton {
    background-color: #1755F4;
    color: white;
    font-family: Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 50px; /* Adjusted margin for better spacing */
}

.exploreButton:hover {
    background-color: #0d3b9f;
}

.horizontalLine {
    width: 90%;
    margin-top: 30px;
    border: 0;
    border-top: 1px solid #ccc;
}

/* Responsive Styling */

@media (max-width: 768px) {
    .heading {
        font-size: 28px;
        line-height: 50px;
    }

    .exploreButton {
        font-size: 16px;
        margin-bottom: 30px; /* Adjusted margin for better spacing */
    }
}

@media (max-width: 576px) {
    .heading {
        font-size: 24px;
        line-height: 40px;
    }

    .exploreButton {
        font-size: 14px;
        padding: 8px 16px; /* Adjusted padding for smaller screens */
        margin-bottom: 20px; /* Adjusted margin for better spacing */
    }
}
