/* MainFooter.module.css */

.footer {
    background: #383838;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
}

.leftContent,
.rightContent {
    display: flex;
    align-items: center;
}

.rightContent p {
    margin: 0 10px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .footer {
        flex-direction: column;
        padding: 20px;
        text-align: center;
    }
    .leftContent,
    .rightContent {
        margin-bottom: 10px;
    }
    .rightContent {
        flex-direction: column;
    }
}
