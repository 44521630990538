/* Navbar.module.css */

.header {
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: white;
    padding: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
}

.logo span a {
    font-family: Satoshi;
    font-size: 35px;
    font-weight: 800;
    line-height: 51.3px;
    text-align: left;
    color: #1755F4;
    padding: 10px 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    margin-bottom: 30px;
}

.nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    font-family: Segoe UI;
    margin-left: 90px;
    margin-top: 6px;
    font-size: 16px;
    font-weight: 700;
}

.nav li {
    margin-right: 50px; /* Adjust margin as needed for spacing between nav items */
    font-family: Segoe UI;
    font-weight: 700;
}

.nav a {
    text-decoration: none;
    color: #000000;
    position: relative; /* Ensure the ::after pseudo-element is positioned relative to the anchor tag */
    transition: color 0.3s ease;
    font-family: Segoe UI;
    font-weight: 400; /* Adjust font weight */
}

.nav a:hover {
    color: #333; /* Change color on hover */
}

/* Style for underline */
.nav a::after {
    content: ''; /* Create an empty content for pseudo-element */
    position: absolute; /* Position the underline absolutely */
    left: 0; /* Align the underline with the left edge of the anchor tag */
    bottom: -5px; /* Adjust the distance between the text and the underline */
    width: 0; /* Initially set the width to 0 */
    height: 2px; /* Set the height of the underline */
    background-color: #1755F4; /* Use the color of the logo for the underline */
    transition: width 0.3s ease; /* Add transition effect to the width */
}

.nav a:hover::after {
    width: 100%; /* Expand the width of the underline on hover */
}

.dropdown {
    position: relative;
   
}

.dropdownMenu {
    display: block;
    position: absolute;
    top: 100%;
    right: -100%;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 10px 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;

}

.dropdownMenu li {
    margin: 0;
}

.dropdownMenu a {
    padding: 10px 20px;
    display: block;
    white-space: nowrap;
    color: #333;
}

.dropdownMenu a:hover {
    background-color: #f5f5f5;
    color: #1755F4;
}


/* Responsive Styling */

@media (max-width: 768px) {
    .header {
        justify-content: space-between;
    }

    .nav ul {
        display: none;
        position: absolute;
        
        left: 0;
        background-color: #ffffff;
        padding: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width: 100%;
        z-index: 10;
        flex-direction: column;
        align-items: flex-start;
    }

    .nav li {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 20px;
        cursor: pointer;
        opacity: 0.7;
    }
     
 .dropdownMenu{
    visibility: hidden;

 }
 .dropdownMenu li{
    visibility: hidden;
 }
    .line {
        width: 100%;
        height: 2px;
        background-color: #333;
    }

    .nav.open ul {
        display: flex;
    }
}
