.blogsContainer {
    padding: 20px;
}

.sectionHeading {
    font-family: Segoe UI;
    font-size: 20px;
    font-weight: 400;
    color: black;
    text-align: center;
    margin-top: -15px;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
    margin-bottom: 50px;
}
.sectionHeading:hover::after {
    content: '';
    position: absolute;
    left: 50%; /* Center the underline */
    bottom: -5px;
    transform: translateX(-50%); /* Adjust to center the underline */
    width: 10%; /* Adjust the width of the underline */
    height: 2px;
    background-color: #1755F4;
}

.blogContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    align-items: center;
}

.imageContainer,
.contentContainer {
    width: 50%;
    padding: 0 10px;
}

.blogImage {
    width: 100%;
    height: auto;
    border-radius: 10%;
}

.blogTitle {
    font-family: Segoe UI;
    font-size: 20px;
    font-weight: 450;
    line-height: 27px;
    margin-bottom: 10px;
    color: white;
    background-color: #1755F4;
    width: fit-content;
    text-align: center;
    padding: 5px 15px; /* Add padding for better appearance */
    margin: 0 auto; /* Center the title */
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blogTitle:hover {
    cursor: pointer;
    opacity: .8;
    border-radius: 5px;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.blogDescription {
    font-family: Segoe UI;
    font-size: 16px;
    line-height: 24px;
    color: black;
    text-align: start;
    padding: 20px 0px 20px 20px;
    border-radius: 8px;
    height: 70%;
    width: 100%;
   
   
    justify-content: center;
}

/* Media Queries */

/* Tablets and smaller screens */
@media (max-width: 768px) {
    .blogContainer {
         display: flex;
         flex-direction: column;
         align-items: center;
         width: 100%;
    }

    .imageContainer,
    .contentContainer {
        width: 80%;
        padding: 0;
    }

    .blogDescription {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    .blogTitle {
        width: fit-content;
        margin: 0 auto;
        border-radius: 10px;
    }
}

/* Mobile phones */
@media (max-width: 480px) {
    .blogDescription {
        width: 70%;
        margin: 10px;
        padding: 15px 0 15px 0; /* Reduce padding for smaller screens */
    }

    .blogTitle {
        font-size: 18px; /* Adjust title size for mobile */
        padding: 4px 10px; /* Adjust padding for title */
        width: fit-content;
        margin: 0 auto;
    }

    .sectionHeading {
        font-size: 18px; /* Adjust section heading size for mobile */
    }

    .blogContainer {
         display: flex;
         flex-direction: column;
         align-items: center;
         width: 100%;
    }

    .imageContainer,
    .contentContainer {
        width: 100%;
        padding: 0;
    }
    .blogTitle{
        margin: auto;
        border-radius: 10px;
    }
}
