/* OurPeople.module.css */
.html{
    width: fit-content;
}
.container {
    margin-top: 50px;
    padding: 0 20px;
}

.smallHeading {
    font-family: Inter, sans-serif;
    font-size: 23px;
    font-weight: 550;
    line-height: 38.73px;
    text-align: left;
    color: #1755F4;
    padding-left: 30px;
}

.description {
    font-family: Inter, sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: #333;
    padding-left: 30px;
}

.moreMembersButton {
    background-color: #ffffff;
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    float: right;
    margin-top: -50px;
    border: 1px solid rgb(20, 19, 19);
    padding-right: 30px;
    
}

.cardContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.card {
    width: 301px;
    height: 433px;
    border: 0.5px solid rgba(93, 88, 88, 0.326);
    border-top: none;
    padding: 25px;
    position: relative;
    height: auto;
}

.cardImage {
    width: 100%;
    height: auto;
}

.caption {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 70%;
    background-color: rgba(255, 255, 255);
    padding: 10px;
    text-align: center;
}

.caption p {
    margin: 5px 0;
}

.cardHeading {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.cardDescription {
    font-family: Inter, sans-serif;
    font-size: 16px;
    color: #555;
    line-height: 20px;
    margin-bottom: 20px;
}

.applyNowButton {
    background-color: #1755F4;
    color: #fff;
    font-family: Inter, sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
@media (max-width: 768px) {
    .cardContainer {
        justify-content: center;
        flex-direction: column;
        margin-top: 60px;
        align-items: center;
    }
    .card {
        width: 50%; /* Adjusted */
        height: auto;
        object-fit: cover;
        margin: 0 10px 0 10px;
    }
    .container{
        padding: 0px;
    }
    .moreMembersButton{
        position: relative;
        top: 35px;
        margin-bottom: 10px;
    }
}

@media (max-width: 576px) {
    .caption {
        width: 100%; /* Adjusted */
    }
}