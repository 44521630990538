/* ContactUsIntro.module.css */

.container {
    padding: 20px;
}

.mainheading{

    display: flex;
    justify-content: center;
    align-items: center;
}
.heading {
    width: 572px;
    height: 78px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 38px;
    font-weight: 450;
    line-height: 58.3px;
    text-align: center;
    color: #000000;
}

.subheading {
    width: 648px;
    height: 82px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 25px;
    font-weight: 300;
    line-height: 40.5px;
    text-align: left;
    color: #000000;
}

.buttonRow {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    padding: 10px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 88px;
    background: #1755F4;
    border: none;
    border-radius: 10px;
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;
    font-weight: 400;
    cursor: pointer;
}

.button svg {
    margin-right: 10px;
}

.bottomText {
    width: 321px;
    height: 35px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 35.1px;
    text-align: center;
    color: #000000;
    margin-left: 35px;
    padding: 25px;
}

@media screen and (max-width: 768px) {
    .heading {
        width: 90%;
        font-size: 32px;
        line-height: 48px;
    }

    .subheading {
        width: 90%;
        font-size: 20px;
        line-height: 30px;
    }
    .mainheading{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .buttonRow {
        flex-direction: column;
        align-items: center;
    }

    .button {
        width: 90%;
        margin-bottom: 10px;
    }

    .bottomText {
        width: 90%;
        margin-left: 0px;
    }
}
