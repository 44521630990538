.container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    padding: 0px;
}

.heading {
    font-family: Inter, sans-serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #1755F4; /* Minimal heading color */
    margin-bottom: 20px;
    padding-left: 20px;
    margin-top: 0px;
    margin-left: 100px;
}

.navItems {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-left: 100px;
}

.navigationItem {
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.2px;
    text-align: left;
    color: black; /* Navigation item color */
    margin-right: 20px; /* Spacing between navigation items */
    position: relative; /* To position the underline */
    padding: 15px;
    padding-bottom: 0px;
}

.navigationItem:hover:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px; /* Spacing between navigation item and underline */
    width: 90%; /* Full width */
    height: 2px;
    background-color: #1755F4; /* Underline color */
}

.contentContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    width: fit-content;
}

.textContainer {
    width: 40%;
    height: auto;
    align-items: left;
    padding: 10px 0 5px 6px;
}

.heading1 {
    font-family: Inter, sans-serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 38.4px;
    color: #000;
    text-align: left;
}

.description {
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #333;
    margin-top: 20px;
}

.tcsLogo {
    width: 350px;
    height: 350px;
    margin-left: 100px;
}

.horizontalLine1 {
    width: 90%;
    margin-top: 30px;
    border: 0;
    border-top: 1px solid #ccc;
}

@media (max-width: 768px) {
    .container {
        align-items: center;
    }

    .heading,
    .navItems,
    .contentContainer {
        margin-left: 0px;
    }

    .textContainer {
        width: 80%;
    }

    .tcsLogo {
        margin-left: 0px;
    }
    .heading1{
        text-align: center;
        font-weight: 450;
    }
}

@media (max-width: 576px) {
    .heading,
    .navItems,
    .contentContainer {
        flex-direction: column;
        align-items: center;
        margin: auto;
        margin-left: 0px;
        width: 90%;
        text-align: center;
    }

    .heading {
        padding-left: 0px;
    }

    .navItems {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .textContainer {
        width: 100%;
    }

    .tcsLogo {
        width: 250px;
        height: 250px;
        margin-left: 0px;
    }
}
