/* ContactForm.module.css */

.contactForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
     margin-left: 10%;
  }
    
.heading {
    font-family: Suisse Screen,sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: black;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
    margin-top: 100px;
    margin-left: 50px;
}

.heading:hover::after {
    content: '';
    position: absolute;
    left: 4%; /* Center the underline */
    bottom: -5px;
    transform: translateX(-50%); /* Adjust to center the underline */
    width: 10%; /* Adjust the width of the underline */
    height: 2px;
    background-color: #1755F4;
}

  
  .row {
    display: flex;
    justify-content: flex-start;
    margin: 10px;
    gap: 4rem;
  }
  
  .formGroup {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .label {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 550;
    line-height: 29.1px;
    text-align: left;
  }
  
  .formInput,
  .formTextarea {
    padding: 0.5rem;
    width: 364px;
    height: 35px;
    border-radius: 8px;
    border: 1px solid #0000001C;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 350;
    line-height: 30.1px;
    border: 1px solid #0000001C;
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.162);
    margin-top: 10px;
  }
  
  .formTextarea {
    width: 800px;
    height: 200px;
    resize: vertical;
  }
  
  .submitButton {
    padding: 0.5rem 1rem;
    background-color: #1755F4;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 22px;
    font-weight: 500;
    line-height: 29.1px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: fit-content;
    height: fit-content;
    margin-bottom: 100px;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  

  
 @media screen and (max-width:768px) {
    .row{
        display: flex;
        flex-direction: column;
        width: 300px;
    }
    .formInput,.formTextarea{
       max-width: 250px;
    }
 }