.blogContainer {
    position: relative;
}

.blogImage {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: top;
    display: block;
}

.blogCaption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.captionHeading {
    font-family: "Suisse Screen", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 25.47px;
    margin-bottom: 10px;
    color: white;
}

.captionText {
    font-family: "Suisse Screen", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
    color: white;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .blogCaption {
        bottom: 10px;
        left: 10px;
    }
}

@media (max-width: 576px) {
    .blogCaption {
        bottom: 5px;
        left: 5px;
    }
}