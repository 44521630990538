.videoContainer {
    position: relative;
    width: 100%;
    max-width: 100%; /* Adjust max-width as needed */
    margin: 0 auto;
}

.video {
    width: 100%;
    height: 70%;
}

.caption {
    position: absolute;
    top: 20%; /* Adjust the distance from the top */
    left: 10%;
    width: 80%;
    text-align: center;
    color: white;
    font-size: 25px;
    font-family: 'Segoe UI', sans-serif;
    padding: 10px;
    box-sizing: border-box;
}

.playPauseButton {
    position: absolute;
    bottom: 20%; /* Adjust the distance from the bottom */
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    border: none;
    color: #1755F4;
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
}

.contactUsButton {
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #1755F4;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.contactUsButton:hover {
    background-color: #0056b3;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .caption {
        top: 15%;
        font-size: 20px;
    }

    .playPauseButton {
        bottom: 15%; /* Adjust the distance from the bottom */
        font-size: 14px;
    }

    .contactUsButton {
        bottom: 15px;
        right: 15px;
        font-size: 14px;
        padding: 8px 16px;
    }
}

@media (max-width: 576px) {
    .caption {
        top: 10%;
        font-size: 18px;
    }

    .playPauseButton {
        bottom: 10%; /* Adjust the distance from the bottom */
        font-size: 12px;
    }

    .contactUsButton {
        bottom: 10px;
        right: 10px;
        font-size: 12px;
        padding: 6px 12px;
    }
}
