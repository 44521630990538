/* ConsultancyAward.module.css */

.container {
    text-align: center;
    margin-bottom: 50px; /* Adjust margin as needed */
}

.heading {
    font-family: Inter, sans-serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 31.47px;
    color: #1755F4;
    text-align: center;
    margin-top: 30px;
}

.description {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 35px;
    color: black;
    text-align: justify;
    margin-bottom: 30px;
    padding: 50px;

}
.description p{
    padding: 5px;
    margin-bottom: 10px;
}
.cardContainer {
    display: flex;
    justify-content: center;
}

.card {
    width: 400px;
    height: max-content;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-left: 20px;
}

.avatar {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: block;
}

.cardTitle {
    font-family: "Suisse Screen", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: center;
}

.cardSubtitle {
    font-family: "Suisse Screen", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: center;
}

.viewDetails {
    font-family:"Suisse Screen", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: center;
    background: #1755F4;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    width: max-content;
    margin: auto;
}

.icons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
    align-items: center;
}

.icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    padding: 5px;
}

.cardDescription {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 350;
    line-height: 21.78px;
    text-align: justify;
    margin-bottom: 20px;
}

.horizontalLine {
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
    .cardContainer {
        flex-direction: column;
        align-items: center;
    }
    .card {
        width: 90%;
        margin-left: 0;
    }
}
