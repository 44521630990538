.services {
    padding: 20px;
    text-align: center;
    margin: 20px 0 20px 0;
}

.heading {
    font-family: Segoe UI;
    margin-top: 20px;
    font-size: 22px;
    font-weight: 400;
    color: black;
    text-align: center;
    margin-top: -15px;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
    margin-bottom: 50px;
}
.heading:hover::after {
    content: '';
    position: absolute;
    left: 50%; /* Center the underline */
    bottom: -5px;
    transform: translateX(-50%); /* Adjust to center the underline */
    width: 10%; /* Adjust the width of the underline */
    height: 2px;
    background-color: #1755F4;
}

.subtitle {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: Satoshi, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 40px;
    width: auto;
}

.viewProductButton {
    font-family: Satoshi, sans-serif;
    font-size: 20px;
    font-weight: 360;
    line-height: 26px;
    padding: 10px 15px;
    background-color: #1755F4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
}

.card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: calc(40% - 20px); /* 2 cards per row with space between */
    margin-bottom: 20px;
    padding: 25px;
    text-align: left;
} 


.cardTitle {
    font-family: Satoshi, sans-serif;
    font-size: 20px;
    font-weight: 450;
    line-height: 24px;
    margin-bottom: 10px;
}

.cardDescription {
    font-family: Satoshi, sans-serif;
    font-size: 15px;
    font-weight: 450;
    line-height: 22px;
    margin-bottom: 20px;
}

.cardLink {
    font-family: Satoshi, sans-serif;
    font-size: 15px;
    font-weight: 350;
    line-height: 24px;
    color: #1755F4;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease;
}

.cardLink:hover {
    color: #004BB5;
}

/* Media Queries */

/* Tablets and smaller screens */
@media (max-width: 768px) {
    .cardsContainer {
        justify-content: center;
    }

    .card {
        width: calc(80% - 20px); /* Single column layout */
    }
}

/* Mobile phones */
@media (max-width: 480px) {
    .subtitle {
        flex-direction: column;
        text-align: center;
    }

    .viewProductButton {
        margin-top: 10px;
    }

    .card {
        width: calc(100% - 20px); /* Single column layout */
    }
}

/* Larger screens */
@media (min-width: 1024px) {
    .card {
        width: calc(45% - 20px); /* 3 cards per row with space between */
    }
}
