.blogContainer {
    position: relative;
    margin-bottom: 30px;
}

.blogImage {
    width: 100%;
    height: auto;
    display: block;
}

.blogCaption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   
    padding: 10px; /* Optional: Adds padding around the text */
    border-radius: 5px; /* Optional: Adds rounded corners to the background */
}

.captionHeading {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 25.47px;
    margin-bottom: 10px;
    color: white;
}

.captionText {
    font-family: "Suisse Screen", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 2.5;
    margin-bottom: 0;
    color: white;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .blogCaption {
        bottom: 15px;
        left: 15px;
        padding: 8px; /* Adjust padding for smaller screens */
       
    }

    .captionHeading {
        font-size: 18px; /* Adjust font size for smaller screens */
        line-height: 22px;
    }

    .captionText {
        font-size: 16px; /* Adjust font size for smaller screens */
        line-height: 1.4;
    }
}

@media (max-width: 576px) {
    .blogCaption {
        bottom: 0px;
        left: 10px;
        padding: 6px; /* Adjust padding for very small screens */
     
    }

    .captionHeading {
        font-size: 16px; /* Adjust font size for very small screens */
        line-height: 20px;
    }

    .captionText {
        font-size: 14px; /* Adjust font size for very small screens */
        line-height: 1.3;
    }
}
