.container {
    position: relative;
}

.smallHeading {
    position: absolute;
    top: 20px;
    left: 20px;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    color: #ffffff;
}

.horizontalLine {
    position: absolute;
    width: 90%;
    height: 2px;
    top: 80px;
    left: 20px;
    background-color: #ccc;
    border: none;
}

.bigHeading {
    position: absolute;
    top: 80px;
    left: 20px;
    font-family: Inter, sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    color: #ffffff;
}

.aboutUsImage {
    width: 100%;
    height: 540px;
    object-fit: cover;
}

.contentContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.textContainer {
    width: 40%;
}

.heading {
    font-family: Inter, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    color: #000;
}

.description {
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #333;
    margin-top: 20px;
}

.tcsLogo {
    width: 450px;
    height: 450px;
    margin-left: 100px;
}

.horizontalLine1 {
    width: 90%;
    margin-top: 30px;
    border: 0;
    border-top: 1px solid #ccc;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .container {
        margin-top: 50px;
    }

    .smallHeading {
        font-size: 20px;
        line-height: 24px;
        top: 10px;
        left: 10px;
    }

    .horizontalLine {
         visibility: hidden;

    }

    .bigHeading {
        font-size: 28px;
        line-height: 36px;
        top: 50px;
        left: 10px;
    }

    .aboutUsImage {
        height: auto;
    }

    .contentContainer {
        flex-direction: column;
    }

    .textContainer {
        width: 80%;
        text-align: center;
    }

    .heading {
        font-size: 28px;
        line-height: 36px;
    }

    .description {
        font-size: 16px;
        line-height: 24px;
    }

    .tcsLogo {
        width: 70%;
        max-width: 350px;
        margin: 20px auto;
        height: auto;
    }

    .horizontalLine1 {
        width: 100%;
    }
}
