/* GlobalPresence.module.css */

.container {
    position: relative;
}

.headings {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
    padding: 10px;
}


.headings h3{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 350;
    font-size: 20px;
    line-height: 25px;
}
.heading {
    font-family: Satoshi;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.3px;
    text-align: left;
}

.image {
    width: 100%;
    height: 500px;
    object-fit: cover;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
}

.overlayText {
    position: absolute;
    top: 120px;
    left: 20px;
    
}

.smallHeading {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 28.4px;
    text-align: left;
    color: white;
}

.text {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 28px;
    font-weight: 550;
    line-height: 28.3px;
    text-align: left;
    color: white;
}

.subtext {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
    font-size: 23px;
    font-weight: 450;
    line-height: 26.3px;
    text-align: left;
    color: white;
}


@media screen and (max-width: 568px) {
    .headings {
       flex-direction: column;
       
    }
   .overlayText {
    margin-top: 200px;
   } 
    
}
