/* GetToKnowAboutUs.module.css */

.container {
    padding: 20px;
    width: 90%;
  
}

.heading {
    width: fit-content;
    height: auto;
    font-family: Inter, sans-serif;
    font-size: 25px;
    font-weight: 450;
    line-height: 20.73px;
    text-align: left;
    color: #1755F4;
    margin-left: 40px;
}

.cardContainer {
    display: flex;
    justify-content: center;
    
    gap: 20px;
    position: relative;
    padding: 20px;
 

    width: calc(25% - 20px);
    width: auto;
}

.card {
    width: 300px;
    height: 364px;
    background: #D9D9D9;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.cardImage {
    width: 100%;
    height: 50%;
    object-fit: cover;
}

.active {
    transform: translateX(0%);
}

.cardContent {
    padding: 20px;
}

.cardHeading {
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
}

.cardDescription {
    font-family: Inter, sans-serif;
    font-size: 18px;
    color: #555555;
    margin-bottom: 20px;
}

.newsroom {
    display: flex;
    align-items: center;
}

.newsroomText {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    color: #333333;
    margin-right: 10px;
}

.readMoreButton {
    background-color: #ffffff;
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.nextButton {
    position: relative;
    top: 0%;
    left: 93%;
    transform: translateY(-50%);
    background-color: #000000;
    color: #FFFFFF;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

@media screen and (max-width: 968px) {
    .heading {
        font-size: 20px;
        width: 200px;
    }
    

    .cardContainer {
        padding: 10px;
        display: flex;
        flex-direction:row;
        flex-wrap: wrap;
       width: auto;
    }

    .card {
        width: 300px;
        height: 324px;
    }

    .cardHeading {
        font-size: 20px;
    }

    .cardDescription {
        font-size: 16px;
    }

    .newsroomText {
        font-size: 16px;
    }

    .readMoreButton {
        font-size: 14px;

    }
    .nextButton{
        left: 40%;
        top: 10px;
    }
}
