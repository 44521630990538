.wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    margin-top: 40px;
}

.container {
    position: relative;
    width: fit-content;
    height: fit-content;
    background: #ffffff3d;
    color: #FFFFFF;
    padding: 26px;
}

.container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: #000000;
    z-index: -1;
}

.headingContainer {
    width: 100%;
    text-align: left;
    font-family: Inter, sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 38.73px;
}

.heading {
    font-family: Inter, sans-serif;
    font-size: 26px;
    font-weight: 550;
    line-height: 30.73px;
}

.cardContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
    flex-wrap: wrap; /* Ensure the cards wrap on smaller screens */
}

.card {
    width: calc(25% - 10px);
    background: #FFFFFF;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    color: #333333; /* Ensure text color is readable */
    margin-bottom: 20px; /* Add spacing between rows of cards */
}

.number {
    font-family: Inter, sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 62.93px;
    color: #1755F4;
}

.description {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    color: #333333;
}

.details {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 19.36px;
    color: #333333;
}

@media (max-width: 1200px) {
    .card {
        width: calc(33.333% - 10px); /* 3 cards per row */
    }
}

@media (max-width: 768px) {
    .headingContainer {
        text-align: center; /* Center align the heading on smaller screens */
    }
    .container {
        background-color: #000000c1;
    }
    .card {
        width: calc(50% - 10px); /* 2 cards per row */
    }
}

@media (max-width: 480px) {
    .card {
        width: calc(100% - 10px); /* 1 card per row */
    }
}
