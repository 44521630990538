.businessContainer {
    padding: 20px;
}

.sectionHeading {
    font-family: Segoe UI;
    font-size: 20px;
    font-weight: 400;
    color: black;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
}

.sectionHeading:hover::after {
    content: '';
    position: absolute;
    left: 50%; /* Center the underline */
    bottom: -5px;
    transform: translateX(-50%); /* Adjust to center the underline */
    width: 10%; /* Adjust the width of the underline */
    height: 2px;
    background-color: #1755F4;
}

.cardContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.card {
    width: calc(33.33% - 20px);
    height: auto;
    align-items: flex-start;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease;
    border-radius: 15px;
    border: 1px solid rgb(216, 211, 211);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card:hover {
    transform: translateY(-10px);
}

.cardImage {
    object-fit: cover;
    width: 100%;
    height: 187px; /* Adjust the height as needed */
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.cardContent {
    padding: 20px;
    text-align: start;
}

.cardTitle {
    font-family: Segoe UI;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    margin-top: 10px;
    cursor: pointer;
    color: #1755F4;
    text-align: center;
}

.cardTitle:hover {
    text-decoration: underline;
    color: #1755F4;
}

.cardDescription {
    font-family: Segoe UI;
    font-size: 15px;
    font-weight: 350;
    line-height: 1.5;
    text-align: left;
    height: 75px; /* Adjust the height as needed */
    overflow: hidden;
}

/* Responsive Styling */

@media (max-width: 1024px) {
    .card {
        width: calc(50% - 20px);
    }
}

@media (max-width: 768px) {
    .card {
        width: calc(50% - 20px);
    }

    .cardImage {
        height: 150px;
    }

    .cardDescription {
        height: 60px;
    }
}

@media (max-width: 576px) {
    .card {
        width: 100%;
    }

    .cardImage {
        height: 200px;
    }

    .cardDescription {
        height: auto;
    }
}
