/* LiveEfficiencyComponent.module.css */

.container {
    text-align: center;
    margin-bottom: 50px; /* Adjust margin as needed */
}

.heading {
    font-family: Suisse Screen, sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: black;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
}

.heading:hover::after {
    content: '';
    position: absolute;
    left: 50%;
    /* Center the underline */
    bottom: -5px;
    transform: translateX(-50%);
    /* Adjust to center the underline */
    width: 40%;
    /* Adjust the width of the underline */
    height: 2px;
    background-color: #1755F4;
    transition: width 0.3s ease;
}


.cardsContainer {
    display: flex;
    justify-content: space-between;
}

.card {
    width: 350px;
    height: 230px;
   
    background-color: #ffffff;
    opacity: 1;
   
    padding: 30px;
    border-radius: 86px;
    box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.2);
    margin: 30px;
   
}

.avatar {
    width: 90px;
    height: 90px;
    margin: 0 auto;
    display: block;
}

.cardHeading {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.2px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
}

.description {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;
    border-radius: 86px;
}

/* Add more styles as needed */

@media (max-width: 768px) {
    .cardsContainer {
        flex-direction: column; /* Stack cards vertically on smaller screens */
        align-items: center; /* Center cards horizontally */
    }

    .card {
        width: calc(100% - 60px); /* Adjust width to fit container */
        margin-left: 30px; /* Adjust margin to center card */
        margin-right: 30px; /* Adjust margin to center card */
    }
}