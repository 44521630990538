/* LatestBlogs.module.css */

.latestBlogs {
    padding: 20px;
}

.heading {
    font-family: Suisse Screen,sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: black;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
}

.heading:hover::after {
    content: '';
    position: absolute;
    left: 50%; /* Center the underline */
    bottom: -5px;
    transform: translateX(-50%); /* Adjust to center the underline */
    width: 10%; /* Adjust the width of the underline */
    height: 2px;
    background-color: #1755F4;
}

.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
}

.card {
    width: calc(100% - 20px);
    margin-bottom: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

@media (min-width: 768px) {
    .card {
        width: calc(50% - 20px); /* Two cards in one row */
    }
}

@media (min-width: 1024px) {
    .card {
        width: calc(33.33% - 20px); /* Three cards in one row */
    }
}

.blogImage {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.caption {
    font-family: Suisse Screen,sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;
    padding: 20px;
}

.details {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    padding: 0 20px;
    font-family: Suisse Screen,sans-serif;
}

.date {
    color: #1755F4;
}

.separator {
    margin: 0 5px;
}

.category {
    color: #1755F4;
}

.description {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 350;
    line-height: 16.94px;
    text-align: left;
    padding: 14px;
    color: black;
}

.readMore {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.readMoreBtn {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    font-weight: 350;
    line-height: 14.52px;
    text-align: left;
    color: #1755F4;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.arrow {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 600;
    height: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.horizontalLine {
    width: 90%;
    margin-top: 30px;
    border: 0;
    border-top: 1px solid #ccc;
}